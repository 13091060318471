var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      staticClass: "dialog-add-quota",
      attrs: { name: _vm.name, "show-close": false, width: "600px" },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_c("div", [_vm._v("Обновление квоты")])]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("ShowQuotaForm", {
        staticClass: "dialog-add-quota__body",
        attrs: { quota: _vm.quota },
      }),
      _c("ShareQuotaForm", { attrs: { quota: _vm.quota } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }