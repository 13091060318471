<template>
  <el-button
    :loading="loading"
    :disabled="disabled"
    :class="classButton"
    :style="width || '100%'"
    :circle="circle"
    @click="handleClick"
  >
    <div class="button__wrap">
      <Icon :name="icon" :width="iconWidth" :height="iconHeight" />
    </div>

    <slot />
  </el-button>
</template>

<script>
import Icon from '@/UI/icon/Icon'

export default {
  name: 'IqButton',
  components: { Icon },
  props: {
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    loading: {
      type: Boolean,
      require: false,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
    },
    width: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'main',
    },
    icon: {
      type: String,
      default: '',
    },
    iconWidth: {
      type: String,
      default: '20px',
    },
    iconHeight: {
      type: String,
      default: '20px',
    },
    circle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classButton() {
      return `button  ${this.size}  ${this.color} `
    },
  },
  methods: {
    handleClick() {
      this.$emit('onClick')
    },
  },
}
</script>

<style scoped lang="sass">
.button
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  border-radius: 4px
  color: #fff
  border: none
  font-family: Roboto, sans-serif
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 22px
  padding: 9px 20px!important
  transition: all .3s ease
  &::v-deep span,
  &__wrap
    display: flex!important
    justify-content: center!important
    align-items: center!important
    flex-wrap: nowrap
    margin-right: 5px
.main
  background: $btn-orange
  &:hover
   background: #C05929
  &.is-disabled
    background: #DCDFE6
.main-dark
  background: $color-orange-primary
  &:hover
    background: fade-out(#EB6626, .2)
.gray
  background: $color-gray-primary
  color: $color-black
  &:hover
    background: fade-out($color-gray-primary, .2)
  &:disabled
    background: #DCDFE6
.error
  background: #F56C6C
  &:hover
   background: #E93C3C
.second
  background: #DCDFE6
  color: #606266
  &:hover
   background: #C0C4CC
.primary
  background: $color-blue-primary!important
  color: $color-white!important
  &.is-disabled
    opacity: 0.3
  &:hover
   background: $color-blue-secondary !important
.fullWidth
  height: 46px
  width: 100%
.fullWidthSmall
  height: 32px
  width: 100%
.medium
  height: 40px
.fullWidthMedium
  height: 40px
  width: 100%
.small
  height: 32px
  font-weight: 400
.mini
  height: 28px
  font-weight: 400
  width: 100%
.extraMini
  height: 28px
  font-size: 12px !important
  padding: 7px 15px !important
  font-weight: 400 !important
  max-width: 220px

//.icon-update
//  .el-icon-arrow-up
//    &:before
//      //content: url(custom.svg)
</style>
