<template>
  <Dialog
    class="dialog-add-quota"
    :name="name"
    :show-close="false"
    width="600px"
  >
    <template #title>
      <div>Обновление квоты</div>
    </template>

    <ShowQuotaForm :quota="quota" class="dialog-add-quota__body" />
    <ShareQuotaForm :quota="quota" />
  </Dialog>
</template>

<script>
import Dialog from '@/UI/dialog/Dialog'
import ShareQuotaForm from './components/ShareQuotaForm.vue'
import ShowQuotaForm from './components/ShowQuotaForm'

import { DIALOG_IMPROVE_QUOTA } from '@/constants/dialogs'
export default {
  name: 'DialogImproveQuota',
  components: {
    Dialog,
    ShowQuotaForm,
    ShareQuotaForm,
  },
  data() {
    return {
      name: DIALOG_IMPROVE_QUOTA,
      wasEdited: false,
      wasImproved: false,
    }
  },
  computed: {
    quota() {
      return this.getDialog(this.name).data
    },
  },
}
</script>

<style lang="sass" scoped>
.dialog-add-quota
  ::v-deep .el-dialog__body
    padding: 0 !important
  &__body
    padding: 20px 16px 16px
</style>
