var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-button",
    {
      class: _vm.classButton,
      style: _vm.width || "100%",
      attrs: {
        loading: _vm.loading,
        disabled: _vm.disabled,
        circle: _vm.circle,
      },
      on: { click: _vm.handleClick },
    },
    [
      _c(
        "div",
        { staticClass: "button__wrap" },
        [
          _c("Icon", {
            attrs: {
              name: _vm.icon,
              width: _vm.iconWidth,
              height: _vm.iconHeight,
            },
          }),
        ],
        1
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }