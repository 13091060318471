var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      class: _vm.classOwn,
      attrs: {
        visible: _vm.visible,
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: _vm.propsDialog.width,
        top: _vm.propsDialog.top,
        "custom-class": _vm.name,
        "modal-append-to-body": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        closed: _vm.onClose,
        "before-close": _vm.handleBeforeClose,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "title",
            fn: function () {
              return [
                _vm._t("title"),
                _vm.showClose
                  ? _c("Icon", {
                      staticClass: "header__close",
                      attrs: {
                        name: "IconClose",
                        width: "30px",
                        height: "30px",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.onClose.apply(null, arguments)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [_vm._t("footer")]
            },
            proxy: true,
          },
        ],
        null,
        true
      ),
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }